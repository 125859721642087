(function () {
    'use strict';

    var TEST_SETUPS = {
        'testLimits': [
            {id: 'testPort', name: 'Test Port'},
            {id: 'maxLength', name: 'Max Length'},
            {id: 'maxLoss', name: 'Maximum Loss'},
            {id: 'description', name: 'Description'}
        ]
    };

    var PRESET_FIELDS = {
        OTDR: {
            'eventDetectionCriteria': [
                {id: 'fiberMode', name: 'Network Type'},
                {id: 'eventsWithLoss', name: 'Events with Loss'},
                {id: 'eventsWithReflectance', name: 'Events with Reflectance'},
                {id: 'endWithLoss', name: 'End with Loss'},
                {id: 'macroBend', name: 'Macro Bend'}
            ],
            'eventPassFailCriteria': [
                {id: 'fiberMode', name: 'Network Type'},
                {id: 'splicePassFail.enable', name: 'Splice P/F Enabled'},
                {id: 'connectorPassFail.enable', name: 'Connector P/F Enabled'},
                {id: 'fiberACI.enable', name: 'FiberACI Enabled'}
            ],
            'linkPassFail': [
                {id: 'fiberMode', name: 'Network Type'},
                {id: 'linkLengthPassFail.linkLengthTolerance', name: 'Link Length Tolerance'},
                {id: 'linkOrlPassFail.enable', name: 'Link ORL P/F Enabled'},
                {id: 'linkLossDistancePassFail.enable', name: 'Link ACI Enabled'}
            ]
        },
        OLTS: TEST_SETUPS,
        Certification: TEST_SETUPS,
        Inspection: TEST_SETUPS,
        ORL: TEST_SETUPS
    };

    /**
     aflToolPresetGrid Directive
     */
    angular.module('aerosApp').directive('aflToolPresetGrid', aflToolPresetGrid);

    aflToolPresetGrid.$inject = ['AFL_GRID', '$state', 'aflGridFilterFactory'];

    function aflToolPresetGrid(AFL_GRID, $state, aflGridFilterFactory) {
        return {
            restrict: 'E',
            template: `<afl-grid 
                    model-type="toolPreset" 
                    license="license"
                    series="series"
                    columns="columns" 
                    filters="filters" 
                    on-reload="onReload()" 
                    default-filters="defaultFilters"></afl-tool-grid>`,
            compile: function () {
                return {pre: link, post: _.noop};
            },
            scope: {
                license: '=',
                presets: '=',
                widths: '=',
                onReload: '&'
            }
        };

        function link(scope) {
            scope.license = $state.license;
            var filterFactory = aflGridFilterFactory($state.params.toolType, 'toolPreset');
            scope.columns = generateListColumns(PRESET_FIELDS[$state.params.toolType][$state.params.presetType],
                scope.widths);
            scope.filters = filterFactory.getFilters();
            scope.defaultFilters = filterFactory.getDefaults();
            setSeries(scope.presets);
            scope.$watch('presets', setSeries);

            function setSeries(presets) {
                scope.series = generateListRows(presets, scope.columns);
            }
        }

        function generateListColumns(columns, widths) {
            var defaultWidth = AFL_GRID.getDefaultWidth(columns.length + 1);

            return _.concat([{
                    headerName: 'Preset Name',
                    field: 'name',
                    width: getWidth({
                        defaultWidth,
                        widths,
                        key: 0
                    })
                }],
                columns.map(function (field) {
                    var suppressFilterState = true;
                    if (field.name == 'Network Type') {
                        suppressFilterState = false;
                    }
                    return {
                        headerName: field.name,
                        field: field.id,
                        width: defaultWidth,
                        suppressFilter: suppressFilterState,
                        template: '<span ng-bind="data.fields ? data.fields[\'' + field.id + '\'] : data[\'' + field.id + '\']">'
                    }
                }),
                [{
                    headerName: 'Actions',
                    templateUrl: '/static/templates/tools/aflToolGridActions.html',
                    suppressFilter: true,
                    width: AFL_GRID.defaultActionWidth
                }]);
        }

        function getWidth({defaultWidth, widths, key}) {
            return (widths && widths[key] !== undefined) ? widths[key]:defaultWidth;
        }

        function generateListRows(rows, columns) {
            return _.map(rows, function (row) {
                return _.extend(row, getProps(row.fields));
            });

            function getProps(fields) {
                return _.pick(fields, _.map(columns, 'field'));
            }
        }
    }

}());
